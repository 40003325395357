import React, { Component } from "react";
import "../../styles/threeplwarehousing.scss";
import Photo from "../Photo";
import { graphql } from "gatsby";
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';



class CustomClearance extends Component {
  static contextType = I18nextContext;
  constructor() {
    super()
    this.state = {
      showMe: true,
      StateUpdate: true,

    }
  }

  Schedule(e, curObj) {
    var status = false;
    if (document.getElementById('feature__' + curObj).classList.contains('active_color')) {
      document.getElementById('feature__' + curObj).classList.remove('active_color');
      document.getElementById('arrow__' + curObj).classList.remove('active_color');
      document.getElementById('feature__' + curObj).classList.add('inactive_color');
      document.getElementById('arrow__' + curObj).classList.add('inactive_color');
      status = false;
    } else {
      document.getElementById('feature__' + curObj).classList.add('active_color');
      document.getElementById('arrow__' + curObj).classList.add('active_color');
      document.getElementById('feature__' + curObj).classList.remove('inactive_color');
      document.getElementById('arrow__' + curObj).classList.remove('inactive_color');
      status = true;
    }

    var custObj = {};
    custObj[curObj] = status;
    this.setState(custObj);
  }
  render() {
    return (
      <div>       
        <div className="banner-feature">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-imgs">
                  <div className="pricings_in_details customs_agency_main" lang={I18nextContext._currentValue.language}>
                    <Link to="/"><Trans>HOME</Trans></Link> / <div className="featuress"><Link to="/product/"><Trans>PRODUCTS</Trans></Link> / </div>
                    <div className="warehouse"><Trans>CUSTOMS CLEARANCE</Trans></div>
                  </div>
                  <div className="sub_main_customdetails" lang={I18nextContext._currentValue.language}><Trans>Achieve Express Clearance in a Tap</Trans></div>
                  <div className="customs_third_content" lang={I18nextContext._currentValue.language}>
                    <Trans>Global regulatory compliant application to get a quick clearance and to create an unstoppable flow in your supply chain business.</Trans>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom_details">
          <div className="container">
            <div className="row ">

              <div className="col-md-12 full_row_alignment">
                <div className="fullimage">
                  <Photo
                    src="Customs Clearance.jpg"
                    className="dimention_image"
                    alt=""
                  />

                </div>
                <div className="customthreepl_contents" lang={I18nextContext._currentValue.language}>
                 <h1 className="heading-size"><Trans>Customs Clearance Software</Trans></h1>
                  <Trans>A SaaS app to help port brokers to clear customs documentation easily. Customs clearance documents can be printed before the vessel arrives in the port with all the required entities in it with the help of the Logipulse Customs Clearance module.</Trans>
                </div>
                <div className="customthreeplsub_headings" lang={I18nextContext._currentValue.language}>
                  <Trans>Rate of service, preparation of bills of entry, preparing the customs clearance, assessment, customs examination and collecting customs out of charge and managing dispatch, delivery, etc. involved in the import process can be done easier than you imagine.</Trans> <br />
                </div>
                <div className="customthreeplsub_headings" lang={I18nextContext._currentValue.language}>
                  <Trans>Preparation of shipping bill (based on shippers instruction), reception of goods, filing for drawback labelling, processing for customs clearance, customs examination and obtaining customs out charge and handling over international carriers, and more that are involved in export can also be handled with Logipulse Customs Clearance app.</Trans><br />
                </div>

              </div>
            </div>
          </div>
        </div>



        <div className="highlights_way" lang={I18nextContext._currentValue.language}>
          <div className="container">
            <div className="Hight_light_heads_content" lang={I18nextContext._currentValue.language}><Trans>Highlights</Trans></div>

            <div className="row full_row_aligns">
              <div className="col-md-6">
                <ul>


                  <li>

                    <div className="material" id="arrow__StateUpdate" onClick={e => this.Schedule(e, 'StateUpdate')} style={{ cursor: "pointer" }}>
                      {
                        this.state.StateUpdate ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }
                    </div>

                    <h5 className="active_color" id="feature__StateUpdate" onClick={e => this.Schedule(e, 'StateUpdate')}>
                      <Trans>State Update</Trans>
                    </h5>
                    {
                      this.state.StateUpdate ? (

                        <div className="subheadings">
                          <Trans>Get the updates of the clearance formalities whether they are in process or completed and so on.</Trans>
                          
                        </div>
                      )
                        : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__rack" onClick={e => this.Schedule(e, 'rack')} style={{ cursor: "pointer" }}>
                      {
                        this.state.rack ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }</div>
                    <h5 id="feature__rack" onClick={e => this.Schedule(e, 'rack')}>
                      <Trans>Transportation Details</Trans>
                    </h5>
                    {this.state.rack ? (
                      <div className="subheadings">
                        <Trans>The information about the transportation involved in the customs clearance andassociated operation can be entered and then retrieved from the platform.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#Transportation" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Automated" onClick={e => this.Schedule(e, 'Automated')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Automated ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Automated" onClick={e => this.Schedule(e, 'Automated')}>
                      <Trans>Port Handler Details</Trans>
                    </h5>
                    {this.state.Automated ? (
                      <div className="subheadings">
                        <Trans>Customs clearance formalities will be handled by authorized brokers. Details of the handlers will be entered and received from the app.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Document" onClick={e => this.Schedule(e, 'Document')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Document ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Document" onClick={e => this.Schedule(e, 'Document')}>
                      <Trans>Delivery Information</Trans>

                    </h5>
                    {this.state.Document ? (
                      <div className="subheadings">
                        <Trans>The details of the delivery process involved will be available from the same module.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Customerportal" onClick={e => this.Schedule(e, 'Customerportal')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Customerportal ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Customerportal" onClick={e => this.Schedule(e, 'Customerportal')}>
                      <Trans>Charges and Payments</Trans>
                    </h5>
                    {this.state.Customerportal ? (
                      <div className="subheadings">
                        <Trans>Charges of the customs clearance service will be available here and the payment can be done precisely from the same platform.</Trans>
                        {/* <div className="know_more">
                          <b><Link to="/features/#Accounting"><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Invoicing" onClick={e => this.Schedule(e, 'Invoicing')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Invoicing ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Invoicing" onClick={e => this.Schedule(e, 'Invoicing')}>
                      <Trans>Notes</Trans>
                    </h5>
                    {this.state.Invoicing ? (
                      <div className="subheadings">
                        <Trans>Any important notes or points that must be remembered can be included through the app so that different people involved in the process can see and follow the instructions.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>



                  <li>
                    <div className="material" id="arrow__Emailing" onClick={e => this.Schedule(e, 'Emailing')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Emailing ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Emailing" onClick={e => this.Schedule(e, 'Emailing')}>
                      <Trans>Multilingual Interface</Trans>
                    </h5>
                    {this.state.Emailing ? (
                      <div className="subheadings">
                        <Trans>Different languages in different locations do not make the operation slow anymore. Languages integrated into the app avoid the barrier of languages in the international process.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#multilingual" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>

                  <li>
                    <div className="material" id="arrow__Customizable" onClick={e => this.Schedule(e, 'Customizable')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Customizable ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Customizable" onClick={e => this.Schedule(e, 'Customizable')}>
                      <Trans>MultiCurrency</Trans>
                    </h5>
                    {this.state.Customizable ? (
                      <div className="subheadings">
                        <Trans>Even though each event in a process happens in different places with different currencies the transactions will be easy with the multi-currency characteristic integration in the app.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#multicurrency" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>



                </ul>
              </div>

              <div className="col-md-6 second_row_align">
                <ul>

                  <li>
                    <div className="material" id="arrow__Movement" onClick={e => this.Schedule(e, 'Movement')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Movement ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Movement" onClick={e => this.Schedule(e, 'Movement')}>

                      <Trans>Direct Mailing and Attachment Facility</Trans>
                    </h5>
                    {this.state.Movement ? (
                      <div className="subheadings">
                        <Trans>Intercommunication or communication with the customers through the mail is easy with a direct mailing facility. The document attachment is an added advantage.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__pdaDevice" onClick={e => this.Schedule(e, 'pdaDevice')} style={{ cursor: "pointer" }}>
                      {
                        this.state.pdaDevice ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__pdaDevice" onClick={e => this.Schedule(e, 'pdaDevice')}>
                      <Trans>Proforma Invoice</Trans>
                    </h5>
                    {this.state.pdaDevice ? (
                      <div className="subheadings">
                        <Trans>Users can generate proforma invoices instantly to provide customers an overview of the charges and payments.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div class="end_divis_customdetails">
          <div className="header_contents_sections text-right"></div>
          <div className="container">
            <div className="row">
              <div class="col-sm-12">
                <Photo
                  src="Bottom Image-11.svg"
                  class="innerimage"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(CustomClearance)
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;